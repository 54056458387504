.overlay {
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(50, 60, 60, 0.7);
  z-index: 5000;
}

.body {
  position: absolute;
  background-color: #d9d9d9;
  width: 100vw;
  height: 70%;
  left: 0;
  z-index: 5100;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  bottom: 0;
  font-family: "TitilliumWeb-Regular", sans-serif !important;

}
.disc {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.foodTag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  width: 12px;
  margin-right: 10px;
  font-size: 13px;
}
.foodTag1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 4px;
  font-size: 13px;
}
.foodName {
  padding: 15px 0px 20px 1px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}
.itemDescription {
  color: #a3a3a3;
  margin-left: 22px;
}
.foodName > h3 {
  font-size: 3vh;
  font-weight: 600;
}
.itemName {
  width: 100px;
}
.exit {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: -32px;
  border-radius: 50%;
  right: -8px;
  background: #fcaf17;
  width: 30px;
  height: 30px;
  z-index: 105011111;
  padding: 3px;
}
.price {
  top: 0px;
  width: 40%;
  color: #000000;
  font-size: 15px;
  float: left;
  text-align: left;
  color: #333333;
  margin-top: 4px;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

.propertySpecify {
  position: relative;
  width: 100%;
  float: left;
  padding: 0px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.propertySpecify::before {
  content: "";
  border-bottom: 2px solid #fcaf17;
  width: 16px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 0%;
}
.propertySpecify::after {
  content: "";
  border-bottom: 2px solid #99a123;
  width: 36px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 20px;
}
.halfPlate,
.mediumPlate,
.fullPlate {
  display: flex;
  justify-content: space-between;
  font-size: 2.2vh;
  margin: 1px;
  padding: 5px 0;
  border-bottom: 1px dotted #c5c5c5;
}
.inputBtn {
  accent-color: #fa4a0c;
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.inputBtn::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 2px;
  margin-left: -18px;
  margin-top: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: #c3001a;
}

.hr {
  width: 100%;
  margin: 5px 0;
}

.selectedOption {
  font-weight: bold;
  font-size: 2.2vh;
  color: #fa4a0c;
}

.selectedOption .label {
  color: black;
}

.quantityLabel {
  color: #000000;
  font-size: 14px;
}
.addonPrice {
  font-weight: 500;
  color: #333333;
  font-size: 14px;
}

.itemNameHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  padding: 5px 20px;
  z-index: 100;
  position: fixed;
  bottom: 0;
  height: 60px;
  left: 0;
  align-items: center;
  border-top: 1px solid #e5e5e5;
}

.headingCenter {
  text-align: center;
  margin-bottom: 0;
}

.SpicyHeading {
  margin: 0;
  font-weight: 500;
}

.button {
  background: #99a123;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 6px 12px;
}
.badge {
  padding: 4px 10px;
  display: inline-block;
  background: #ebebeb;
  font-size: 14px;
  cursor: pointer;
  margin: 3px;
}
.selectedBadge {
  background: #99a123;
  color: #fff;
  border-color: #99a123;
}
.cust-instruction-row {
  margin-left: -3px;
}
.foodItemName {
  /* width: 60%; */
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}

.foodItemDescription {
  font-size: 14px;
  line-height: 1.42857143;
}

.overlay .modal-dialog {
  max-height: 95vh;
}

.modalDialog {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  bottom: 0;
  position: fixed;
  left: 0;
  /* filter: blur(0px); */
  background-color: #fff;
  padding: 20px;
  border-radius: 30px 30px 0 0;
  width: 100%;
}
.modalBody {
  max-height: calc(80vh - 70px);
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.mainDiv {
  border: #dee2e6 1px solid;
}

.itemName {
  margin: 0;
  height: max-content;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  margin-right: 5px;
  font-weight: 550;
  line-height: 20px;
  margin-bottom: 7px;
  /* text-align: center; */
  word-break: word-break;
}

.hidden {
  visibility: hidden;
}

.mediumLabel {
  color: #757272;
  height: max-content;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  /* flex: 1 1 0; */
  /* line-height: 16.94px; */
  margin-bottom: 4px;
  word-break: keep-all;
  /* height: auto; */
}

/* .quantity {
  color: #757272;

  max-width: 308px;
  width: fit-content;
  font-size: 17px ;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  text-transform: lowercase;
  font-family: "Inter", sans-serif;

  font-weight: 500;
} */

.instruction {
  width: 90% !important;
  overflow: hidden;
  text-wrap: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contain {
  display: inline;
}

.customDetail {
  color: #c1bebe;
  margin-bottom: 5px;
}

.customize {
  color: var(--second-color);
  height: 16px;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
}

.counter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  /* width: 60px; */
  /* background-color: #fa4a0c; */

  color: var(--primary-color);
  font-size: 13px;
  width: 57.48px;
  height: 22.11px;
}

.inputQuantity {
  width: 24px;
  height: 22.15px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18.15px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #090909;
  border: 1px solid var(--primary-color);
  /* border-top: none;
  border-bottom: none; */
  /* border-radius: 5px; */
  /* background-color: #f1c5b5; */
  background-color: var(--primary-color);
  color: white;
}

.inputQuantity:focus {
  /* border: none; */
  outline: none;
}

.inputQuantity:active {
  /* border: none; */
  outline: none;
}

.price {
  width: 36px;
  height: 18px;
  font-family: "Inter", sans-serif;
  margin-top: 3px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #090909;
}

.counter span {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 15px;
}

/* .custom-description:before {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 40px;
  left: 0;
  width: 100%;
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255) 100%);
}

.custom-description {
  max-height: 70px;
  overflow: hidden;
  position: relative;
  line-height: 17px;
  transition: 0.5s;
} */

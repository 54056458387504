.container {
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 12px 20px;
  z-index: 10001;
  color: rgba(144, 144, 144, 1);
  /* margin-bottom: 100px !important; */
}
.hello {
  margin-left: 10px;
}
.highlight {
  background: none;
  color: black;
  font-weight: 600;
}
.left mark {
  padding: 0;
}
.hr {
  margin: 0 20px;
}

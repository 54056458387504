.sidebarInside {
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sidebarLogin {
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.width {
  width: 90%;
}
.user {
  height: 100px;
  /* width: 16 0px; */
  /* width: 100px; */
  border-radius: 50%;
  cursor: pointer; /* Optional: Change cursor to pointer on hover */
  transition: transform 0.2s ease; /* Optional: Add smooth transition */
}

.zoomed {
  height: 300px; /* Adjust as needed */
  width: 300px; /* Adjust as needed */
}
.userdetail {
  width: 100%;
  text-align: left;
}
.addimage {
  font-size: 17px;
  line-height: 18.15px;
  margin-bottom: 20px;
  color: #4f4f4f;
  font-weight: 300;
}
.userdetail hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: rgba(139, 139, 139, 1);
}
.userinformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
}
.userinformation span {
  color: #8b8b8b;
  padding-left: 20px;
  font-size: 18px;
  line-height: 19.36px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icons {
  margin-left: 10px;
  font-size: 20px !important;
  width: 16px;
  height: 18px;
  color: black;
}
.logout {
  width: 100%;
  height: 10vh;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.logout hr {
  color: rgba(139, 139, 139, 1);
  width: 90%;
}
.logout span {
  color: rgba(139, 139, 139, 1);
}
.back {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  color: rgba(139, 139, 139, 1);
}
.controlicons {
  font-size: 24px;
}
.loginbtn {
  border: none;
  background-color: rgb(205, 24, 48);
  border-radius: 4px;
  color: #ffffff;
  width: 120px;
  height: 30px;
  opacity: 0.06px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 6px;
}
.editProfile {
  border: none;
  background-color: rgb(205, 24, 48);
  border-radius: 4px;
  color: #ffffff;
  width: 120px;
  height: 40px;
  opacity: 0.06px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
}

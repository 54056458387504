.main {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: none;
}
.header {
  width: 93vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  font-size: 23px;
  font-weight: 700;
}
.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.outer {
  position: absolute;
  width: 100vw;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Img {
  height: 110px;
  width: 110px;
}

.ErrMsg {
  align-items: center;
}
.whoopsHeading {
  text-align: center;
  font-size: 36px;
  margin-top: 80px;
  margin-bottom: 20px;
  letter-spacing: 1%;
  color: #fa4a0c;
}
.msg {
  text-align: center;
  padding: 10px 30px;
  font-size: 20px;
  color: rgb(129, 116, 116);
  font-weight: 600;
}

.button {
  position: absolute;
  bottom: 10vh;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #df8160;
  border-radius: 15px;
  padding: 8px 40px;
  color: #fa4a0c;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 700;
  background-color: #fff;
}

.card {
  position: fixed;
  top: 27%;
  left: 4%;
  transform: translate(-50%, -50%);
  padding: 25px;
  border-radius: 20px;
  background-color: white;
  z-index: 1000; /* Ensure it's above the backdrop */
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  width: 90%;
  max-width: 400px;
  overflow: hidden;
}
.exit {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: -55px;
  border-radius: 50%;
  right: -17px;
  background: #fa4a0c;
  width: 30px;
  height: 30px;
  z-index: 105011111;
  padding: 3px;
  cursor: pointer;
}
.icon {
  margin-top: 40px;
  text-align: center;
  color: #fa4a0c;
  text-align: center;
  position: relative;
}

.ordertext {
  margin-top: 35px;
  font-size: 24px;
  color: #fa4a0c;
  text-align: center;
}

.thanktext {
  color: black;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.orderid {
  color: black;
  margin-top: 5px;
  font-size: 18px;
  text-align: center;
}

.tableno {
  color: #555555;
  margin-top: 20px;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 20px;
  height: fit-content;
  width: fit-content;
  background-color: #8e8c8c1a;
  margin: 7px auto;
  text-align: center; /* Align text to the center */
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure it's above other content */
  transition: opacity 0.3s ease-in-out;
}

.card.visible {
  opacity: 1;
}
.visible {
  opacity: 0.6;
}
.confetti {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 150px;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
}

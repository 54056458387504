@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
:root{
  --primary-color: #c3001a;
  --second-color:#fcaf17;
  --third-color:#838881;
  --fourth-color:#99a123;
  --fifth-color:#afa43b;
}
* {
  font-family: 'Titillium Web', sans-serif !important;
}
.button {
  width: 73px;
  height: 30px;
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
  border: none;
  padding: 4px 3px;
  font-weight: 600;
}

.input-field {
  width: 336px;
  height: 42px;
  padding: 10px;
  border-radius: 15px;
  border: none;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: black;
  background-color: "#D9D9D9";
  outline: none;
}
.input-field:focus {
  outline: 2px solid #c0c0c0; /* Set light grey outline when focused */
}
#reader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* #html5-qrcode-button-camera-permission {
  width: max-content;
  border: none;
  background-color: blue;
  border-radius: 10px;
} */
#html5-qrcode-anchor-scan-type-change span {
  margin-top: 30px !important;
}

/* CSS */
#html5-qrcode-button-camera-permission {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 0.9em;
  margin: 5px;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 15px;
}

#html5-qrcode-button-file-selection {
  align-items: center;
  background-color: rgba(240, 240, 240, 0.26);
  border: 1px solid #dfdfdf;
  border-radius: 16px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 18px;
  justify-content: center;
  line-height: 21px;
  max-width: 100%;
  padding: 14px 22px;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

#html5-qrcode-button-file-selection:active,
#html5-qrcode-button-file-selection:hover {
  outline: 0;
}

#html5-qrcode-button-file-selection:hover {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.19);
}

@media (min-width: 768px) {
  #html5-qrcode-button-file-selection {
    font-size: 20px;
    min-width: 200px;
    padding: 14px 16px;
  }
}
/* #reader__dashboard_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 170px;
} */
/* NOTIFICATION STYLES */

#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}
.commonFlex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.underlineStyle {
  position: relative;
 }
 .underlineStyle span:before {
   content: "";
   border-bottom: 3px solid #fcaf17;
   width: 25px;
   position: absolute;
   float: left;
   bottom: -1px;
   left: 0%;
 }
 .underlineStyle span:after {
   content: "";
   border-bottom: 3px solid #99a123;
   width: 60px;
   position: absolute;
   float: left;
   bottom: -1px;
   left: 29px;
 }
.details {
position: relative;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding-top: 15px;
  padding-bottom: 20px;
}
.details hr{
  margin: 8px 0;
}
.billdetail{
  position: relative;
}
.billdetail span:before {
  content: "";
  border-bottom: 3px solid #fcaf17;
  width: 25px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 0%;
}
.billdetail span:after {
  content: "";
  border-bottom: 3px solid #99a123;
  width: 60px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 29px;
}
.badge {
  background-color: rgba(0, 194, 255, 0.12);
  color: #00c2ff !important;
}
.tablename {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #858181;
  text-align: center;
  margin-bottom: 5px;
}

.outer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.inner {
  height: 160px;
  width: 160px;
  animation: blink-animation 1s infinite alternate;
}
@keyframes blink-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.heading{
 margin-bottom: 15px;
  /* margin-top: 5px; */
}
.heading span{
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  padding-bottom: 2px;
  position: relative;
  font-family: "Inter", sans-serif;
}
.heading span:before {
  content: "";
  border-bottom: 3px solid #fcaf17;
  width: 25px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 0%;
}
.heading span:after {
  content: "";
  border-bottom: 3px solid #99a123;
  width: 60px;
  position: absolute;
  float: left;
  bottom: -1px;
  left: 29px;
}
.details hr{
  margin : 10px 0;
}
.items{
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
}
.badge {
  background-color: rgba(0, 194, 255, 0.12);
  color: #00c2ff !important;
}
.tablename {
  font-weight: 500;
  color: #6d6a6a;
    text-align: center;
}
.price{
  color: #555;
}
.flex {
  display: flex;
  justify-content: space-between;
  max-width: 100% !important;
}

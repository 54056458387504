.container {
  /* display: flex; */
  justify-content: space-between;
  /* align-items: center; */
  width: 100vw;
  height: 100vh;
  /* padding: 0 20px; */
  /* position: fixed; */
  /* top: -1000; */
  background-color: white;

  /* z-index: 1; */
  /* overflow-y: hidden; */
}
.input {
  width: 100%;
  border: 1px solid rgb(121, 116, 116);
  border-radius: 8px;
  height: 45px;
  outline: none;
  font-size: 18px;
  padding: 0% 40px;
}
.input::placeholder {
  font-size: 16px;
}
.inputContainer {
  position: sticky;
  top: 0;
  width: 100%;
}
.searchIcon,
.backIcon,
.crossIcon {
  font-size: 26px;
  font-weight: 600;
}
.backIcon {
  margin-right: 20px;
}
.searchIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgb(121, 116, 116);
}
.crossIcon {
  position: absolute;
  color: rgb(121, 116, 116);
  top: 10px;
  right: 10px;
}
.result {
  position: fixed;
  top: 110px;
  width: 100%;
  background-color: white;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 76px;
}
.noResult {
  font-size: 18px;
  text-align: center;
  color: rgb(121, 116, 116);
  margin-top: 20px;
  padding: 20px;
}
@media (max-width: 512px) {
  .result {
    height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/* .outer {
  height: 100vh;
  width: 100vw;
  background-color: #f6f6f6;
}
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.welcome {
  padding: 25px 20px 30px 20px;
  width: 90%;
  max-width: 400px;
  background-color: white;
  box-shadow: 0px 4px 10.1px 0px #00000040;
  text-align: center;
  height: auto;
  gap: 0px;
}

.PoweredBy {
  margin-bottom: 0.3vh;
  font-size: 2.3vh;
  color: rgba(142, 140, 140, 1);
}
.Sensation {
  height: 50%;
  width: 100%;
}
.welcomeapp {
  font-weight: 600;
  word-wrap: break-word;
  font-size: 36px;
  color: var(--primary-color);
  font-weight: 700;
  line-height: 43.57px;
}
.qr {
  margin-top: 75px;
  width: 280px;
  height: 280px;
  gap: 0px;
}
.cameraavl {
  position: relative;
  margin-top: 2vh;
  max-width: 400px;
  height: 46vh;
  overflow: hidden;
}
.scanner {
  height: 100%;
  overflow: hidden;
  margin-top: 2.7vh;
  width: 75%;
  object-fit: fill;
}

.scanning {
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
  z-index: 10;
  height: 32vh;
  width: 90%;
  color: black;
} */

.outer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://img.freepik.com/premium-photo/top-view-delicious-food-assortment_23-2148941571.jpg?w=740");
}
.welcomeapp {
  font-weight: 600;
  word-wrap: break-word;
  font-size: 28px;
  color:white;
  font-weight: 700;
  line-height: 43.57px;
}
.scanner{
  height: 300px;
  width: 300px;
  object-fit: cover;
}
.scanner {
  --s: 50px; /* Size of the corner */
  --t: 5px;  /* Thickness of the border */
  --g: 0px; /* Gap between the border and the image */
  
  padding: calc(var(--g) + var(--t));
  outline: var(--t) solid #B38184; /* Border color */
  outline-offset: calc(-1 * var(--t));
  mask: 
    conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 
    0 0 / calc(100% - var(--s)) calc(100% - var(--s)),
    linear-gradient(#000 0 0) content-box;
  transition: outline-offset 0.4s;
  animation: blink 1s infinite;
}

.scanner:hover {
  outline-offset: calc(var(--g) / -1);
}

@keyframes blink {
  0% {
    outline-color: #B38184; /* Initial border color */
  }
  50% {
    outline-color: transparent; /* Make the border invisible */
  }
  100% {
    outline-color: #B38184; /* Border color back to initial */
  }
}
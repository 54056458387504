/* RepeatPrevious.module.css */

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba(50, 60, 60, 0.7);
  z-index: 500;
  width: 100%;
}

.popup {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  bottom: 0;
  position: fixed;
  left: 0;
  /* filter: blur(0px); */
  background-color: #fff;
  padding: 20px;
  border-radius: 30px 30px 0 0;
  width: 100%;
}

.popup h5 {
  font-weight: bold;
}

.popup h6 {
  font-weight: bold;
}

.popup hr {
  background-color: rgba(142, 142, 142, 0.59);
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.button-wrapper > div {
  margin-right: 50px;
}

.button-wrapper > div:last-child {
  margin-right: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Offside&display=swap");
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-family: "Offside", sans-serif;
  font-style: normal;
  color: #283b41;
}
.error img {
  height: 400px;
  width: 400px;
}

.screen {
  height: 100vh;
  width: 100vw;
  background-color: #e9e7e6;
}
.controls {
  position: absolute;
  top: 20px;
  left: 20px;
}
.main {
  position: absolute;
  padding: 45px 15px;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  /* /* justify-content: space-evenly; */
  align-items: center;
  background-color: #f1f1f1;
}
.profile {
  height: 137px;
  width: 137px;
  position: relative;
}
.profile img {
  height: 137px;
  width: 137px;
  border-radius: 50%;
}
.edit {
  position: absolute;
  bottom: 10%;
  right: 0.1%;
  padding: 8px;
  border-radius: 50%;
  background-color:#B61413;
}
.fileInput {
  display: none;
}
.form {
  height: 33vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: left;
  padding: 20px 0px;
  margin-top: 5vh;
  width: 100%;
  max-width: 500px;
  align-items: center;
}
.form input {
  height: 48px;
  border: 1px solid #aaaaaa;
  outline: none;
  padding-left: 10px;
  font-size: 18px;
  width: 100%;
  max-width: 400px;
 margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  line-height: 1rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B61413;
  color: white;
  border: none;
 
  padding: 5px;
  width: 200px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 4px 5.4px 2px #00000040;
  font-size: 20px;
  line-height: 24.2px;
}
.error {
  color: red;
  font-size: 12px;
  padding: 10px;
  padding-left: 10px;
}
.errorT {
  color: transparent;
  font-size: 12px;
  padding: 10px;
  padding-left: 10px;
}
@media (max-width: 740px) {

  .main{
    top:36%;
  width: 100%;
  background-color:#e9e7e6;
  }
  .form {
    height: 25vh;
  }
  .button{
    width: 140px;
  }
}
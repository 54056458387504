.main {
  padding: 20px;
  padding-top: 0px;
  width: 100%;
  height: 100vh;
  background-color: white;
  /* overflow: hidden; */
}
.overflow {
  overflow-y: scroll;
  height: 80vh;
}

.overflow::-webkit-scrollbar {
  display: none;
}
.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.outer {
  width: 100%;
  height: max-content;
  /* border-radius: 20px; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding: 0px 14px;
  border: #dee2e6 1px solid;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itemcard {
  margin: 10px 0;
}
.button {
  width: 88vw;
  height: 56px;
  border: none;
  color: white !important;
  border-radius: 10px;
  background-color: var(--primary-color);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  cursor: pointer;
  margin: 30px auto;
  font-size: 20px;
  text-decoration: none !important;
}
.icon {
  color: black;
  font-size: 26px;
  margin-top: 20px;
}
.h6 {
  /* color: #009f23 !important; */
  padding: 3px 10px;
  background: rgba(20, 255, 0, 0.12);
  border-radius: 5px;
}
.h1 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
}

.ordNo {
  font-size: 18px;
  margin-bottom: 0px;
  color: grey;
  border-radius: 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.HeadingMyOrders{
    height: 29px;
    top: 55px;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 550;
    line-height: 29.05px;
    margin: 0;
   }
   .btn2Class:before {
  animation: none !important;; 
}
.outer {
  width: 100%;
  height: max-content;
  margin-top: 10px;
  padding: 14px;
  border: 1px solid #dee2e6;
}
.ordername {
  font-size: 20px;
  color: var(--fifth-color);
  /* padding: 16px 16px; */
  width: 90%;
  white-space: nowrap;
  line-height: 24.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}
.orderdetails {
  display: flex;
  justify-content: space-between;
}
.orderTable {
  display: flex;
  justify-content: space-between;
  color: #6c5858;
  font-size: 15px;
}

.datetime {
  padding-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c5858;
  font-size: 13px;

}
.datetime span{
  display: flex;
  align-items: center;
  font-size: 14px;
}
.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button {
  width: 151px;
  height: 36px;
  margin-right: 16px;
  border: none;
  color: white;
  border-radius: 15px;
  background-color: rgba(250, 74, 12, 1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  font-size: 15px;
  line-height: 18.15px;
}
.hr {
  color: #757272;
  margin: 8px 0;
}
.orderdetails h4 {
  color: #fa4a0c;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02rem;
  margin-right: 5px;
}
.orderdetails h6 {
  color: #009f23;
  padding: 3px 10px;
  background: rgba(20, 255, 0, 0.12);
  border-radius: 5px;
}

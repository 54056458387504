.navlink {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 18px 10px 10px 10px;
  margin-bottom: 0px;
  background-color: white;
  overflow: scroll;
  background: #e4e4e4;
}
.navlink::-webkit-scrollbar {
  display: none;
}

 .navlink li {
            font-size: 13px;
            letter-spacing: 1px;
            color: #8e8c8c;
            border-radius: 10px;
            border-bottom-left-radius: 0%;
            border-bottom-right-radius: 0%;
            padding: 10px;
            position: relative;
            list-style: none;
            margin: 10px 0;
        }

        .navlink li::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border-bottom-left-radius: 0%;
            border-bottom-right-radius: 0%;
            border-bottom: 1px solid #fa4a0c;
            transform: scaleX(0);
            transition: transform 0.3s;
   
        }

        .navlink li:hover::before {
            transform: scaleX(1);
            margin-top: 2px;
             border-bottom: 2px solid #fa4a0c;
        }


.active {
  color: #fa4a0c !important;
  border-bottom:2px solid #fa4a0c;
  background-color: #e2dedc;
} 


.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 80;
  top: 85px;
  background: #e4e4e4;
  position:fixed;
}
.navlink::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.foodItem {
  height: max-content;
  width: 100%;
  border-bottom: 2px solid #ececec;
  /* margin: 14px auto; */
  /* border-radius: 20px; */
  /* box-shadow: 0px 4px 15.1px 0px #00000040; */
  padding: 10px 20px 15px 20px;
  background: white;
}
.itemName {
  padding-top: 10px;
  font-size: 19px;
  font-weight: 600;
  line-height: 24.2px;
  margin-bottom: 10px;
}
.iteminfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.pricetag {
  color: #333;
  font-weight: 600;
  height: 22px;
  gap: 0px;
  font-size: 14px;
  line-height: 21.78px;
  letter-spacing: 0.05em;
  /* width: 65px; */
  text-align: center;
  padding-top: 2px;
}
.customize {
  color: var(--second-color);
  font-size: 14px;
  font-weight: 500;
}
.disc {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.foodTag {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

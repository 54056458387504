/* .counter {
  display: flex;
  border: 2px solid var(--primary-color);
  overflow: hidden;
  background-color: #f9d9cd;
  width: 70px;
  height: 30px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
}
.counter span {
  background-color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 23.4px;
  line-height: 24.2px;
}
.counter button{
  width: 23.3px;
  font-size: 19px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: white;
  color: var(--third-color);
}

*/ .counter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  /* width: 60px; */
  /* background-color: #fa4a0c; */
  color: var(--primary-color);
  font-size: 13px;
  width: 65px;
  height: 25px;
}

.counter span {
  font-weight: 600;
  font-size: 15px;
}

.inputQuantity {
  width: 24px;
  height: 23px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  /* color: #090909; */
  border: 1px solid var(--primary-color);
  /* border-top: none;
  border-bottom: none; */
  /* border-radius: 5px; */
  /* background-color: #f1c5b5; */
  background-color: var(--primary-color);
  color: white;
}
.outerContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  overflow: scroll;
}
.exit {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: -14px;
  border-radius: 50%;
  right:10px;
  background: #fcaf17;
  width: 30px;
  height: 30px;
  z-index: 105011111;
  padding: 3px;
}
.navigation {
  height: 84px;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 30px 20px;
  font-family: "Inter", sans-serif;
  display: flex;
  color: black;
  text-decoration: none;
}
.modalDialog {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  bottom: 0;
  position: fixed;
  left: 0;
  /* filter: blur(0px); */
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  width: 100%;
}
.overlay{
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(50, 60, 60, 0.7);
  z-index: 5000;
}
.verifytext {
  font-size: 24px;
  text-decoration: none;
  font-weight: 500;
  line-height: 29.05px;
  color: #000;
}
.navigation span {
  margin: 0 auto;
  line-height: 29.05px;
}
.back {
  margin-bottom: 5px;
}
.verifymain {
  margin: 0 auto;
  width: 90%;
  height: max-content;
  color: #000;
  font-family: "Inter", sans-serif;
}
.text {
  margin-top: 10px;
  font-size: 21px;
  line-height: 29.05px;
}
.sent {
  font-size: 20px;
  padding: 1vh;
  color: #a9a9a9;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.otp {
  margin: 2vh auto;
}
.otpInput {
  width: 48px !important;
  height: 42px;
  margin: 0 0.5rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* box-shadow: rgba(50, 50, 93, 0.2) 0px 35px 70px -15px,
    rgba(0, 0, 0, 0.25) 0px 20px 40px -25px,
    rgba(10, 37, 64, 0.25) 0px -1px 4px 0px inset; */

  box-shadow: 0px 2px 2px 0px #00000040;
}
.timing {
  color: #6d6d6d;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
}

.resend {
  color: #fa4a0c;
  cursor: pointer;
  font-weight: 500;
  line-height: 19.36px;
  font-family: "Inter", sans-serif;
}

.success {
  animation: blink-glow 1s infinite;
}
.failure {
  animation: blink-glow-red 1s infinite;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 3rem; */
}
.PoweredBy {
  font-size: 2.3vh;
  color: rgb(187, 183, 183);
  /* margin-bottom: 3px; */
  margin: 0;
}
@keyframes blink-glow {
  0% {
    border-color: transparent;
    box-shadow: 0 0 1px #129903, 0 0 2px #129903, 0 0 3px #129903,
      0 0 4px #129903, 0 0 5px #129903;
  }
  50% {
    border-color: green;
    box-shadow: none;
  }
  100% {
    border-color: transparent;
    box-shadow: 0 0 1px #129903, 0 0 2px #129903, 0 0 3px #129903,
      0 0 4px #129903, 0 0 5px #129903;
  }
}
@keyframes blink-glow-red {
  0% {
    border-color: transparent;
    box-shadow: 0 0 1px #de2d19, 0 0 2px #de2d19, 0 0 3px #de2d19,
      0 0 4px #de2d19, 0 0 5px #de2d19;
  }
  50% {
    border-color: #de2d19;
    box-shadow: none;
  }
  100% {
    border-color: transparent;
    box-shadow: 0 0 1px #de2d19, 0 0 2px #de2d19, 0 0 3px #de2d19,
      0 0 4px #de2d19, 0 0 5px #de2d19;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  /* Firefox */
}
/* you are verified css */
.outer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9990;
  background-color: rgba(241, 241, 244, 0.6);
}
.main {
  position: fixed;
  bottom: 0;
  height: 50vh;
  width: 100vw;
  z-index: 9999;
  border-top-right-radius: 10%;
  border-top-left-radius: 10%;
}
.greentick {
  margin-top: 5vh;
  height: 21vh;
  width: 20vh;
  animation: pulse-animation 1.5s infinite alternate ease-in-out;
}
.main h5 {
  margin-top: 3.5vh;
  font-size: 4vh;
  font-weight: 600;
  color: rgba(86, 178, 44, 1);
}
.main p {
  margin-top: 3vh;
  font-size: 2vh;
  color: rgba(109, 109, 109, 1);
}
/* #fa4a0c */
@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/*  */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.modalDialog {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  bottom: 0;
  position: fixed;
  left: 0;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  width: 100%;
  overflow-y: visible;
}
.controlicons {
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(50, 60, 60, 0.7);
  z-index: 5000;
  overflow-y: visible;
}
.exit {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: -16px;
  border-radius: 50%;
  right: 18px;
  background: #fcaf17;
  width: 30px;
  height: 30px;
  z-index: 105011111;
  padding: 3px;
  cursor: pointer;
}
.formContainer {
  overflow-y: auto;
}
/* .main {
  border-radius: 20px;
  box-shadow: 0px 4px 15.1px 0px #00000040;
  margin: 20px;
} */
.mainContainer {
  position: relative;
  text-align: center;
  padding: 20px 40px;
  width: 100%;
  z-index: 2;
  border-radius: 0px;
  background-color: #fff;
}
@media screen and (max-width: 600px) {
  .modalDialog {
    height: auto !important;
    max-height: 100vh !important;
  }

  .formContainer {
    max-height: calc(100vh - 80px);
  }
}
.companyLogo {
  max-width: 180px;
  height: auto;
  width: 50%;
  float: left;
}
.logoImg {
  max-width: 100%;
  width: 100%;
  height: 80px;
  position: relative;
  border-bottom: 2px solid #6d000f;
  border-radius: 20px 20px 0px 0px;
}
.LoginForm {
  display: flex;
  flex-direction: column;
}
.input {
  height: 28px;
  width: 97%;
  font-size: 15px;
  font-weight: 600;
  padding: 0px;
  padding-bottom: 3px;
  color: #1d2b34;
  border: none;
  border-bottom: 1px solid #bbbbbb;
  box-shadow: none !important;
  border-radius: 0px !important;
  outline: none;
}

.h1 {
  width: 100%;
  font-size: 25px;
  margin-bottom: 25px;
  text-align: left;
  font-weight: 300;
  color: #292929;
}
.formIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  outline: none;
  background: #fff;
  color: #98a122;
  border: 1px solid #ccc;
  width: 27px;
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
}
.formGroup {
  width: 100%;
  float: left;
  padding-left: 35px;
  position: relative;
  padding-bottom: 10px;
  margin-top: 10px;
  text-align: left;
}
.formLabel {
  line-height: 18px;
  vertical-align: top;
  margin-bottom: 5px;
  font-size: 14px;
  color: #8c8c8c;
}
.formStyles {
  font-size: 15px;
  color: #1d2b34;
  line-height: 24px;
  padding-top: 0px;
}
.continueButton {
  text-align: end;
  margin: 0px 10px;
}
.button {
  background: #99a123;
  color: #ffffff !important;
  border-radius: 0px !important;
  line-height: 34px;
  padding: 4px 15px;
  margin-top: 10px;
  border: none;
  font-size: small;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: max-content;
  width: max-content;
}
.PoweredBy {
  margin-bottom: 0.3vh;
  margin-left: 15px;
  font-size: 15px;
  line-height: 18.15px;
  font-family: "Inter", sans-serif;
  color: #8e8c8c;
}
.Sensation {
  /* height: 50%; */
  height: 25px;
  width: 121px;
  /* width: 100%; */
}
.error {
  color: red;
  float: left;
  font-size: 12px;
  /* padding-left: 10px; */
}
/* ask to verify card css */
.outerAskingCard {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9990;
  background-color: rgba(241, 241, 244, 0.6);
}
.mainAskingCard {
  position: fixed;
  bottom: 0;
  height: 52vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  z-index: 9999;
  border-top-right-radius: 10%;
  border-top-left-radius: 10%;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.mainAskingCard div {
  margin: 2.5vh 0;
}
.hi {
  /* font-size: 4.5vh; */
  font-size: 32px;
  line-height: 38.73px;
  font-weight: 500;
  color: #fa4a0c;
  font-family: "Inter", sans-serif;
}
.wavinghand {
  font-size: 4vh;
}
.contact {
  line-height: 42.36px;
  font-size: 3.5vh;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 1);
  font-family: "Inter", sans-serif;
}
.asking {
  color: #555;
  font-size: 24px;
  line-height: 29.05px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.skip {
  font-size: 17px;
  color: #4f4f4f;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  display: block;
  padding: 10px;
}

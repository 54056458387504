.outer {
  background-color: white;
  padding-left: 20px;
  padding-top: 5px;
  max-height: 270px;
  overflow: scroll;
  width: 100%;
  position: fixed;
  top: 49px;
  box-shadow: 0px 2px 10.8px -3px #0d0b0b96;
  left: 0;
  z-index: 10;
  padding-bottom: 10px;
}
.outer div {
  cursor: pointer;
  position: relative;
}
.link {
  text-decoration: none;
  color: black;
  display: block;
  width: 100%;
}

.bottomdiv {
    height: fit-content;
    width: 100%;
    /* background-color: var(--primary-color); */
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    left: 0;
    box-shadow: 0px 4px 5.4px 2px #00000040;
  }
  
  .orderInfo {
    color: #f0e2dc;
  }
  
  .button {
    border: none;
    background-color: rgb(205, 24, 48);
    border-radius: 10px;
    color: #ffffff;
    width: 140px;
    height: 48px;
    opacity: 0.06px;
    font-size: 20px;
    font-weight: 500;
    line-height: 20.57px;
  }
  
  .itemadded {
    font-size: 17px;
    line-height: 20.57px;
  }
  
  .totalbill {
    font-size: 17px;
    line-height: 24.2px;
  }
  .price {
    font-size: 17px;
    font-weight: 500;
    line-height: 20.57px;
  }
  